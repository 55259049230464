import React, { useEffect, useState } from "react";
import {
  dashboard_invoice_approve,
  dashboard_invoice_green_tick,
  dashboard_invoice_order_date,
  dashboard_invoice_order_history,
  dashboard_invoice_payment_done,
  dashboard_logo_merchant,
  calender_icon,
  info_details_sample_car,
  merchant_dashboard_location_icon,
  pdf,
} from "../../../assets/images";
import {
  DeliverySchedule,
  DriverAmount,
  MerchantInvoiceApprove,
} from "../../../types";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { FieldValues, useForm } from "react-hook-form";
import ErrorModal from "../../ErrorModal";
import SuccessModal from "../../SuccessModal";
import ConfirmationModal from "../../ConfirmationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ImagePopupModal from "../ImagePopupModal";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Carousel,
} from "react-bootstrap";
import "./style.css";
import CustomInput from "../../CustomInput";
import AuthRules from "../../../rules/AuthRules";
import { ToastContainer, toast } from "react-toastify";
import CustomCheckBox from "../../customCheckbox";

const CoustomerInvoiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [status, setStatus] = useState<string>("");
  const [comments, setComments] = useState("");
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const OrderId = state && state.OrderId;
  console.log("new", OrderId);
  const {
    data: invoiceDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["invoiceDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.ORDER_DETAILS}/${OrderId}/details`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });
  const { data: commentMessage } = useQuery({
    queryKey: ["commentMessage", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GET_COMMENTS}/${OrderId}`
      );
      console.log("commentMessagestatuss", commentMessage);
      return response.data;
    },
  });
  useEffect(() => {
    if (invoiceDetails) {
      setStatus(invoiceDetails.insurance_order ? "true" : "false");
    }
  }, [invoiceDetails]);

  const {
    data: vehicleReassign,
    isLoading: isLoadingVehicle,
    isError: isErrorVehicle,
  } = useQuery({
    queryKey: ["vehicleList", status],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.VEHICLE_REASSIGN}/${status}`
      );
      return response.data;
    },
    enabled: !!status,
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAcceptConfirmationModal, setShowAcceptConfirmationModal] =
    useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(false);
  const [acceptConfirmationMessage, setAcceptConfirmationMessage] =
    useState("");
  const [rejectConfirmationMessage, setRejectConfirmationMessage] =
    useState("");

  const [showCarChangeConfirmationModal, setShowCarChangeConfirmationModal] =
    useState(false);
  const [acceptCarConfirmationMessage, setAcceptCarConfirmationMessage] =
    useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [selectedCar, setSelectedCar] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [plateNumber, setPlateNumber] = useState("");
  const [plateNumberError, setPlateNumberError] = useState("");
  //345678909876545678
  const {
    control,
    formState: { isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (invoiceDetails?.products?.[0]?.productId) {
      setSelectedCar(invoiceDetails.products[0].productId);
    }
  }, [invoiceDetails]);
  //------------------------------------------------------------------------

  const handleSlotChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedSlot(e.target.value);
    console.log("selectedSlot", selectedSlot);
  };

  const handleCarChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedCar(selectedValue);
    setAcceptCarConfirmationMessage(
      "Are you sure you want to change the vehicle?"
    );
    setShowCarChangeConfirmationModal(true);
  };

  const handleCommentsChange = (event: any) => {
    setComments(event.target.value);
  };

  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  // const merchantId = decodedToken["merchant-id"];
  // const businessArea = decodedToken["planet-id"];
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  // Check if decodedToken exists and has the "planet" property
  const businessArea =
    decodedToken && decodedToken["planet"] ? decodedToken["planet"] : null;

  const productChange = useMutation({
    mutationFn: async () => {
      return await axiosInstance.put(
        API_URL.CAR_CHANGE_UPDATE,
        {
          merchant_id: merchantId,
          order_number: OrderId,

          update_product_id: selectedCar,
          previous_product_id: invoiceDetails?.products[0]?.productId,
        },
        {
          headers: {
            location: "1,2",
            language: "english",
          },
        }
      );
    },
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data) {
        setIsSuccess(true);
        toast.success("Vehicle changed successfully!", {
          position: "top-right",
        });
      }
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        setIsSuccess(false);
        toast.error("Failed!! Please try again.", {
          position: "top-right",
        });

        // setErrorMessage(error?.response?.data?.message);
        // setShowErrorModal(true);
      }
    },
  });

  //--------------------------------------------------------------------------

  const handleOpenConfirmationModal = (message: string) => {
    setAcceptConfirmationMessage(message);
    setShowAcceptConfirmationModal(true);
  };

  const handleSubmit = () => {
    handleOpenConfirmationModal("Are you sure you want to accept?");
  };

  const handleApproval = useMutation({
    mutationFn: async () => {
      const delivery_date = invoiceDetails?.delivery_date;
      const datePart = delivery_date.split("T")[0];
      const [startTime, endTime] = selectedSlot.split("-");

      // let startTime = "";
      // let endTime = "";
      const response = await axiosInstance.put(
        `${API_URL.APPROVE_REJECT}/${OrderId}`,
        {
          order_status: "MERCHANT_ORDER_ACCEPT",
          delivery_date: delivery_date,
          // delivery_start_time: slot.delivery_start_time
          //   ? `${datePart}T${slot.delivery_start_time}`
          //   : delivery_date,
          // delivery_end_time: slot.delivery_end_time
          //   ? `${datePart}T${slot.delivery_end_time}`
          //   : delivery_date,
          delivery_start_time: startTime
            ? `${datePart}T${startTime}`
            : delivery_date,
          delivery_end_time: endTime ? `${datePart}T${endTime}` : delivery_date,
          merchant_comment: comments,
          // free_upgrade: isChecked,
          free_upgrade_by_merchant: isChecked,
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      );
      return response.data;
    },
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      navigate("/merchant/invoice");
    },
    onError: (error: any) => {
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
        setShowErrorModal(true);
      }
    },
  });

  //----------------------------------------------------------

  const { data: deliverySlot } = useQuery<DeliverySchedule[], Error>({
    queryKey: ["slot"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.SLOT);
      return response.data;
    },
  });

  //--------------------------------------------------------------

  // const handleReject = () => {
  //   handleRejection.mutate();
  // };

  const handleOpenRejectConfirmationModal = (message: string) => {
    setRejectConfirmationMessage(message);
    setShowRejectConfirmationModal(true);
  };

  const handleReject = () => {
    handleOpenRejectConfirmationModal("Are you sure you want to reject?");
  };
  const handleAssign = () => {
    console.log("Assign button clicked");
    //navigate("/merchant/ApprovedDeliveryBoysList");
  };
  console.log("checkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
  const handleRejection = useMutation({
    mutationFn: () =>
      axiosInstance.put(
        `${API_URL.APPROVE_REJECT}/${OrderId}`,
        {
          order_status: "MERCHANT_ORDER_REJECTED",
          merchant_comment: comments,
        },
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      ),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/merchant/invoice");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const [showImagePopupModal, setShowImagePopupModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const handleimagepopover = () => {
    setShowImagePopupModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
  };

  const [loadModal, setLoadModal] = useState(false);
  const [imageSource, setImageSource] = useState("");

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const emiritiesDocs = Object.keys(customerDocs).filter((key) =>
        key.startsWith("EMIRITIES")
      );

      emiritiesDocs.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const licenseDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("LICENSE")
      );

      licenseDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const VisaDocs = Object.keys(customerDocs)?.filter((key) =>
        key.startsWith("VisitVisa")
      );

      VisaDocs?.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails?.customer_details?.customerDocs) {
      const customerDocs = invoiceDetails?.customer_details?.customerDocs;

      const PassportDocs = Object.keys(customerDocs).filter((key) =>
        key.startsWith("Passport")
      );

      PassportDocs.forEach((docKey) => {
        const documentId = customerDocs[docKey];

        if (documentId !== undefined && documentId !== null) {
          axiosInstance
            .get(`${API_URL.CUSTOMER_DOCUMENTS}/${documentId}`, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              if (response && response.data) {
                const contentType = response.headers["content-type"];
                const isPdf = contentType === "application/pdf";
                const isImage = contentType.startsWith("image/");

                if (isPdf) {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const objectUrl = URL.createObjectURL(blob);

                  const downloadLink = document.getElementById(
                    `${docKey}DownloadLink`
                  ) as HTMLAnchorElement;
                  downloadLink.href = objectUrl;
                  downloadLink.style.display = "block";
                  downloadLink.download = `${docKey}.pdf`;

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = pdf;
                } else if (isImage) {
                  const blob = new Blob([response.data], { type: contentType });
                  const imageUrl = URL.createObjectURL(blob);

                  const imageElement = document.getElementById(
                    `${docKey}_IMAGE`
                  ) as HTMLImageElement;
                  imageElement.src = imageUrl;
                  imageElement.onclick = () => {
                    setImageSource(imageUrl);
                    setShowImagePopupModal(true);
                  };

                  const imageSizeKB = response.data.byteLength / 1000;
                  console.log(`${docKey} Image Size:`, imageSizeKB, "KB");
                }
              } else {
                console.error(`Failed to fetch document for ${docKey}`);
              }
            })
            .catch((error) => {
              console.error(`Error fetching ${docKey}:`, error);
            });
        } else {
          console.warn(`Document ID for ${docKey} is null or undefined`);
        }
      });
    }
  }, [invoiceDetails]);
  //4567890987

  const [isChecked, setChecked] = useState(false);
  const handleCheckBoxChange = () => {
    setChecked(!isChecked);
  };
  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-center pt-5 pb-2">
        <p className="info-text mt-1" id="license"></p>
      </div>
      <div className="col-md-12 text-start div-bg-invoice-details">
        <div className="card card-bg-invoice-details">
          <div className="card-body px-5">
            <div className="row  pt-4 pb-3">
              <div className="col-md-6">
                <table className="invoice-table-width">
                  <tr className="invoice-table-tr-first">
                    <td className="invoice-table-td">Purchase order #</td>
                    <td>Order Created At</td>
                  </tr>
                  <tr>
                    <td className="invoice-text-yellow">
                      {invoiceDetails?.orderId}
                    </td>
                    <td>{formatDate(invoiceDetails?.createdAt)}</td>
                  </tr>
                </table>
                <div className="col-md-12 pt-3">
                  {/* <div className="invoice-div-head">Invoice #</div>
                  <div className="invoice-text-yellow">DB 6001 2024 </div> */}
                  <div className="col-md-6 pt-2 pb-2">
                    {/* <img
                      src={invoiceDetails?.products[0]?.productImageUrls}
                      alt=""
                      className="img-fluid"
                    /> */}
                    <Carousel className="managemycarlist" controls={false}>
                      {invoiceDetails?.products?.[0]?.productImageUrls?.map(
                        (imageUrl: any, index: number) => (
                          <Carousel.Item
                            className="image-managemycar-height"
                            key={index}
                          >
                            <img
                              className="img-merchant-managecar"
                              src={imageUrl}
                              alt={`Product Image ${index + 1}`}
                            />
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="invoice-text-yellow">
                    {invoiceDetails?.products[0]?.productName}
                  </div>
                </div>
                <div className="col-md-12 invoice-div-head pt-3">Location</div>
                <div className="col-md-12 invoice-div-data">
                  {" "}
                  {invoiceDetails?.delivery_location?.address}{" "}
                  {/* {invoiceDetails?.delivery_address?.state},{" "}
                  {invoiceDetails?.delivery_address?.country} */}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Number of Days
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.rental_days}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  License Number
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.drivingLicenseNumber}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  License Expiry Date
                </div>
                <div className="col-md-12 invoice-div-data">
                  {formatDate(
                    invoiceDetails?.customer_details?.licenceExpiryDate
                  )}
                </div>
              </div>
              <div className="col-md-6 border-start-invoice-details">
                <div className="col-md-12 invoice-div-head">Customer Name</div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.firstname}&nbsp;
                  {invoiceDetails?.customer_details?.lastname}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Customer email
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.customer_details?.email}
                </div>
                <div className="col-md-12 invoice-div-head pt-3">
                  Backoffice Comments
                  {commentMessage && commentMessage.length > 0 && (
                    <div className="col-md-12 ">
                      {commentMessage.map((item: any, index: number) => (
                        <div
                          key={index}
                          className="pt-2 col-md-12 invoice-div-data"
                        >
                          {item.message}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.order_comment}
                </div> */}

                <div className="col-md-12 invoice-div-head pt-3">
                  Delivery Time
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.delivery_preferred_time}
                </div>

                {invoiceDetails?.delivered_by === "CUSTOMER" && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Pickup By
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}
                {invoiceDetails?.delivered_by === "KIRKOS_EXECUTIVE" && (
                  <>
                    <div className="col-md-12 invoice-div-head pt-3">
                      Delivery By
                    </div>
                    <div className="col-md-12 invoice-div-data">
                      {invoiceDetails?.delivered_by}
                    </div>
                  </>
                )}
                <div className="col-md-12 invoice-div-head pt-3">
                  Additional Options
                </div>
                <div className="col-md-12 invoice-div-data">
                  {invoiceDetails?.products[0]?.babySeatAmount !== 0 &&
                    invoiceDetails?.products[0]?.babySeatAmount !== null && (
                      <li>Baby Seat</li>
                    )}{" "}
                  {invoiceDetails?.products[0]?.paiInsuranceAmount !== 0 &&
                    invoiceDetails?.products[0]?.paiInsuranceAmount !==
                      null && <li>PAI Insurance</li>}{" "}
                  {invoiceDetails?.products[0]?.windShieldProtection !== 0 &&
                    invoiceDetails?.products[0]?.windShieldProtection !==
                      null && <li>WindShield Protection</li>}{" "}
                  {invoiceDetails?.products[0]?.additionalDriverAmount !== 0 &&
                    invoiceDetails?.products[0]?.additionalDriverAmount !==
                      null && <li>Additional Driver</li>}{" "}
                  {invoiceDetails?.products[0]?.scdwAmount !== 0 &&
                    invoiceDetails?.products[0]?.scdwAmount !== null && (
                      <li>SCDW Insurance</li>
                    )}{" "}
                </div>

                {/* ---------------------------- Insurance  details-------------------------------------------- */}
              </div>
              {invoiceDetails && invoiceDetails?.insurance_order === true && (
                <div className="col-md-12 pt-3">
                  <div className="row bg-delivery-details pb-3">
                    <div className="col-md-12 invoice-div-head pt-3 fs-6 invoice-text-yellow">
                      Insurance Details
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance company
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_company}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Policy
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_policy}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Claim
                      </div>
                      <div className="invoice-div-data">
                        {invoiceDetails?.insurance_claim}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="invoice-div-head pt-3">
                        Insurance Expiry Date
                      </div>
                      <div className="invoice-div-data">
                        {formatDate(invoiceDetails?.insurance_expiry_date)}
                      </div>
                    </div>

                    {invoiceDetails?.plate_number && (
                      <>
                        <div className="col-md-6">
                          <div className="invoice-div-head pt-3">
                            Customer Plate Number
                          </div>
                          <div className="invoice-div-data">
                            {invoiceDetails?.plate_number}
                          </div>
                        </div>
                      </>
                    )}
                    {invoiceDetails?.chassis_number && (
                      <>
                        <div className="col-md-6">
                          <div className=" invoice-div-head pt-3">
                            Customer Chassis Number
                          </div>
                          <div className=" invoice-div-data">
                            {invoiceDetails?.chassis_number}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="col-12">
                <div className="row">
                  <div className="col-md-6  align-content-center">
                    <div className="row mt-4">
                      {console.log("invoiceDetails:", invoiceDetails)}
                      {invoiceDetails?.customer_details?.customerDocs &&
                        Object.keys(
                          invoiceDetails?.customer_details?.customerDocs
                        )
                          .filter((key) => key.startsWith("EMIRITIES"))
                          .map((key) => (
                            <div
                              key={key}
                              id={`${key}_container`}
                              className="col-md-6 mb-2"
                            >
                              <p>EMIRATES: </p>

                              <img
                                id={`${key}_IMAGE`}
                                src={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                style={{ width: 75, height: 75 }}
                                alt={`${key} document`}
                              />

                              <a
                                className="download-img"
                                id={`${key}DownloadLink`}
                                href={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                title={`Download ${key} PDF`}
                                style={{ display: "none" }}
                              >
                                Download {key} PDF
                              </a>
                            </div>
                          ))}

                      {invoiceDetails?.customer_details?.customerDocs &&
                        Object.keys(
                          invoiceDetails?.customer_details?.customerDocs
                        )
                          .filter((key) => key.startsWith("LICENSE"))
                          .map((key) => (
                            <div
                              key={key}
                              id={`${key}_container`}
                              className="col-md-6 mb-2"
                            >
                              <p>LICENSE: </p>

                              <img
                                id={`${key}_IMAGE`}
                                src={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                style={{ width: 75, height: 75 }}
                                alt={`${key} document`}
                              />

                              <a
                                className="download-img"
                                id={`${key}DownloadLink`}
                                href={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                title={`Download ${key} PDF`}
                                style={{ display: "none" }}
                              >
                                Download {key} PDF
                              </a>
                            </div>
                          ))}

                      {invoiceDetails?.customer_details?.customerDocs &&
                        Object.keys(
                          invoiceDetails?.customer_details?.customerDocs
                        )
                          .filter((key) => key.startsWith("VisitVisa"))
                          .map((key) => (
                            <div
                              key={key}
                              id={`${key}_container`}
                              className="col-md-6 mb-2"
                            >
                              <p>VISA: </p>

                              <img
                                id={`${key}_IMAGE`}
                                src={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                style={{ width: 75, height: 75 }}
                                alt={`${key} document`}
                              />

                              <a
                                className="download-img"
                                id={`${key}DownloadLink`}
                                href={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                title={`Download ${key} PDF`}
                                style={{ display: "none" }}
                              >
                                Download {key} PDF
                              </a>
                            </div>
                          ))}

                      {invoiceDetails?.customer_details?.customerDocs &&
                        Object.keys(
                          invoiceDetails?.customer_details?.customerDocs
                        )
                          .filter((key) => key.startsWith("Passport"))
                          .map((key) => (
                            <div
                              key={key}
                              id={`${key}_container`}
                              className="col-md-6 mb-2"
                            >
                              <p>Passport: </p>

                              <img
                                id={`${key}_IMAGE`}
                                src={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                style={{ width: 75, height: 75 }}
                                alt={`${key} document`}
                              />

                              <a
                                className="download-img"
                                id={`${key}DownloadLink`}
                                href={
                                  invoiceDetails.customer_details.customerDocs[
                                    key
                                  ]
                                }
                                title={`Download ${key} PDF`}
                                style={{ display: "none" }}
                              >
                                Download {key} PDF
                              </a>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-md-12 pt-5">
                      <div className="card invoice-card-inner">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-1  text-center">
                              <img
                                src={dashboard_invoice_order_history}
                                height={"16px"}
                                alt=""
                              />
                            </div>
                            <div className="col invoice-text-yellow">
                              Order History
                            </div>
                          </div>
                          <div className="col-md-11 pt-3">
                            <div className="d-flex ">
                              <div className="col-1  text-center">
                                <img
                                  src={dashboard_invoice_order_date}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className="invoice-div-head">
                                  Purchase order Date
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(invoiceDetails?.createdAt)}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={dashboard_invoice_green_tick}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className=" invoice-div-head">Status</div>
                                <div className=" invoice-text-green">
                                  {invoiceDetails?.orderStatus}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={merchant_dashboard_location_icon}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className=" invoice-div-head">
                                  Delivery Date & Location
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(invoiceDetails?.delivery_date)} |
                                  {invoiceDetails?.delivery_location?.address}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex ps-2">
                              <div className="col-1 text-center hor_line"></div>
                            </div>
                            <div className="d-flex pt-2">
                              <div className="col-1  text-center">
                                <img
                                  src={merchant_dashboard_location_icon}
                                  height={"18px"}
                                  alt=""
                                />
                              </div>
                              <div className="col">
                                <div className=" invoice-div-head">
                                  Recollection Date & Location
                                </div>
                                <div className="invoice-inner-card-text">
                                  {formatDate(
                                    invoiceDetails?.recollection_date
                                  )}{" "}
                                  |{" "}
                                  {
                                    invoiceDetails?.recollection_location
                                      ?.address
                                  }
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex ps-2">
                          <div className="col-1 text-center hor_line"></div>
                        </div> */}
                            {/* <div className="d-flex pt-2">
                          <div className="col-1  text-center">
                            <img
                              src={dashboard_invoice_payment_done}
                              height={"18px"}
                              alt=""
                            />
                          </div>
                          <div className="col">
                            <div className=" invoice-text-green">
                              {invoiceDetails?.payment_status}
                            </div>
                          </div>
                        </div> */}
                          </div>
                        </div>

                        {/* </div> */}
                        {/* </div> */}
                      </div>
                      {/* <div className="col-md-12 pt-5">
                    <div className="card invoice-card-inner">
                      <div className="card-body">
                        <div className="col invoice-text-yellow">
                          Delivery Slot/Time
                        </div>
                        <div className="col-md-12 invoice-div-data">
                          <div className="col-md-12 invoice-div-data">
                            9:00 AM - 01:00 PM
                          </div>
                          <div className="col-md-12 invoice-div-data">
                            02:00 PM - 04:00 PM
                          </div>
                          <div className="col-md-12 invoice-div-data">
                            05:00 PM - 07:00 PM
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 pt-5">
                <div className="card invoice-card-inner-mechant">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 pt-3 invoice-div-data">
                        <div className="col invoice-text-yellow">Vehicle</div>
                        <select
                          className="select-invoice-bg-black"
                          value={selectedCar}
                          onChange={handleCarChange}
                        >
                          <option>Select Vehicle</option>
                          {isLoadingVehicle && <option>Loading...</option>}
                          {isErrorVehicle && (
                            <option>Error loading vehicles</option>
                          )}
                          {vehicleReassign &&
                            vehicleReassign.map(
                              (vehicle: {
                                product_id: string;
                                product_name: string;
                              }) => (
                                <option
                                  key={vehicle.product_id}
                                  value={vehicle.product_id}
                                >
                                  {vehicle.product_name}
                                </option>
                              )
                            )}
                        </select>
                      </div>

                      <div className="col-md-6 pt-3 invoice-div-data">
                        <div className="col invoice-text-yellow">
                          Delivery Slot/Time
                          <span className="merchantStar">*</span>
                        </div>
                        <select
                          className="select-invoice-bg-black"
                          value={selectedSlot}
                          onChange={handleSlotChange}
                        >
                          <option value="">Select Time slot</option>
                          {isLoading && <option>Loading...</option>}
                          {isError && <option>Error loading slots</option>}
                          {deliverySlot &&
                            deliverySlot.map((slot) => (
                              <option
                                key={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                                value={`${slot.delivery_start_time}-${slot.delivery_end_time}`}
                              >
                                {`${slot.delivery_start_time} - ${slot.delivery_end_time}`}
                              </option>
                            ))}
                        </select>
                      </div>
                      {/* <div className="col-12 pt-3">
                        <label className="invoice-text-yellow form-label">
                          Comments
                        </label>
                        <textarea
                          name=""
                          id=""
                          rows={5}
                          className="select-invoice-bg-black-textarea"
                        ></textarea>
                      </div> */}
                      <div className="col-12 pt-3">
                        <label className="invoice-text-yellow form-label">
                          Comments
                        </label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows={5}
                          className="select-invoice-bg-black-textarea"
                          value={comments}
                          onChange={handleCommentsChange}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12 px-3">
                      <div className="d-flex pt-3 justify-content-end invoice-text-yellow checkbox-order">
                        <CustomCheckBox
                          isChecked={isChecked}
                          onChange={handleCheckBoxChange}
                        />
                        <span className="upgrade-text">Upgrade by vendor</span>
                      </div>
                    </div>
                    <div className=" text-end pt-3">
                      <button
                        className="invoice-approve-btn"
                        disabled={!selectedSlot}
                        onClick={handleSubmit}
                      >
                        {" "}
                        <img
                          src={dashboard_invoice_approve}
                          height={"14px"}
                          alt=""
                        />
                        &nbsp;ACCEPT
                      </button>
                      {/* <button
                        className="invoice-approve-btn px-3"
                        disabled={!plateNumber || isButtonDisabled}
                        onClick={handleSubmit}
                        ///onClick={() => mutation.mutate()}
                      >
                        Update
                      </button> */}
                      <button
                        className="invoice-reject-btn"
                        onClick={handleReject}
                      >
                        {" "}
                        <span className="invoice-paid-failed-style">
                          {" "}
                          &#10006;
                        </span>
                        &nbsp; REJECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Oops! Something went wrong"
      />

      <ImagePopupModal
        isOpen={showImagePopupModal}
        //  onClose={onCloseImagePopupModal}
        src={imageSource}
        onClose={() => setShowImagePopupModal(false)}
        message={""}
      />

      <ConfirmationModal
        isOpen={showAcceptConfirmationModal}
        onClose={() => setShowAcceptConfirmationModal(false)}
        onConfirm={() => {
          handleApproval.mutate();
          setShowAcceptConfirmationModal(false);
        }}
        message={acceptConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showRejectConfirmationModal}
        onClose={() => setShowRejectConfirmationModal(false)}
        onConfirm={() => {
          handleRejection.mutate();
          setShowRejectConfirmationModal(false);
        }}
        message={rejectConfirmationMessage}
      />

      <ConfirmationModal
        isOpen={showCarChangeConfirmationModal}
        onClose={() => setShowCarChangeConfirmationModal(false)}
        onConfirm={() => {
          productChange.mutate();
          setShowCarChangeConfirmationModal(false);
        }}
        message={acceptCarConfirmationMessage}
      />

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
};

export default CoustomerInvoiceDetails;
