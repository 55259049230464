import React, { useState } from "react";
import { Bell, Search } from "react-bootstrap-icons";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import {
  dashboard_search_normal,
  kirkos_merchant_profile,
} from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";

interface GarageNavBarProps {
  currentPage: string;
  notifications: string[];
  handleNotificationClick: (notification: string) => void;
  newNotificationsCount: number;
  setNewNotificationsCount: React.Dispatch<React.SetStateAction<number>>;
}

const GarageNavBar: React.FC<GarageNavBarProps> = ({
  currentPage,
  notifications,
  handleNotificationClick,
  newNotificationsCount,
  setNewNotificationsCount,
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const navigate = useNavigate();

  const handleProfile = () => {
    setShowProfile(!showProfile);
  };

  const handleNotification = () => {
    setShowNotification(!showNotification);
    setNewNotificationsCount(0);
  };

  const handleSetActiveNavigationLogout = (nav: string) => {
    navigate("/");
  };

  return (
    <div className="nav-bar-style">
      <Navbar expand="lg" className="nav-pills navbar-light nav-tab-style">
        <Navbar.Collapse>
          <div className="col-4 navheaderdiv">
            <Navbar.Brand href="#" className="navbar-brand-style">
              Garage
            </Navbar.Brand>
          </div>
        </Navbar.Collapse>
        <Nav className="right-side-nav justify-content-end">
          <div className="justify-content-end d-inline-flex">
            <li className="nav-item nav-bar-li">
              <a className="nav-link search-bar-bg p-1">
                <form className="d-flex">
                  <label htmlFor="searchbox">
                    <img src={dashboard_search_normal} width="30" height="30" />
                  </label>
                  <input
                    id="searchbox"
                    type="text"
                    className="search-bar-input-box px-1"
                    placeholder="Search your car activity log"
                  />
                </form>
              </a>
            </li>

            <Dropdown
              show={showNotification}
              onToggle={handleNotification}
              style={{ width: 55 }}
              className="pt-1"
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="notification-hover p-0 relative-div "
              >
                <div
                  className={notifications.length > 0 ? "icon-bell-hover" : ""}
                >
                  <Bell size={20} color=" white " />
                </div>

                {newNotificationsCount > 0 && (
                  <div className="notification-badge icon-hover ">
                    {newNotificationsCount}
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="display-drop-down-notification">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <Dropdown.Item
                      className="word-in-line "
                      key={index}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="">{notification}</div>
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item className="word-                                                                                                               in-line">
                    No new notifications
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={showProfile}
              onToggle={handleProfile}
              style={{ width: 100 }}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="profile-hover pt-1 px-2"
              >
                <img
                  src={kirkos_merchant_profile}
                  className="rounded-pill profile-logo"
                  alt="Profile"
                />
                <span className="custom-d-none-navbar">&nbsp;Profile</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="display-drop-down">
                <Dropdown.Item>
                  <Link to="/garage/garageprofile">Profile</Link>
                </Dropdown.Item>
                <Dropdown.Item href="#">Edit</Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to="/garageonboard"
                    onClick={() => handleSetActiveNavigationLogout("logout")}
                  >
                    Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default GarageNavBar;
