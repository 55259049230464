const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

const API_URL = {
  //CONSUMER
  SEND_OTP: `carhire-customer-merchant/customer/service/otp/sendotp`,
  VERIFY_OTP: `carhire-customer-merchant/customer/service/otp/validateotp`,
  REGISTER: `carhire-customer-merchant/customer/service/consumers/register`,
  T_OTP: `carhire-customer-merchant/authentication/generate/totp`,
  LOGIN: `carhire-customer-merchant/authentication/login`,
  PVT_KEY: `carhire-customer-merchant/authentication/privateKey`,
  DOC_UPLOAD: `carhire-customer-merchant/customer/service/documents/upload`,
  BOOKING_NOW: `carhire-customer-merchant/customer/service/consumers`,
  DELIVERY_ADDRESS: `carhire-customer-merchant/customer/service/master/deliveryaddress`,
  PRODUCT_SEARCH: `carhire-product-consumer//product-data/location-and-filter`,
  CUSTOMER_DETAILS:
    "carhire-customer-merchant/customer/service/consumers/details",
  CLEAR_CART: "/carhire-customer-merchant/customer/service/consumers",

  COUNTRY: `carhire-customer-merchant/customer/service/master/country`,
  STATE: `carhire-customer-merchant/customer/service/master/state/`,

  BOOKED_ORDERS: `carhire-order/order/service/consumers/orders/customer`,

  //MERCHANT

  SIGNUP_MERCHANT:
    "carhire-customer-merchant/customer/service/consumers/details",
  LICENSE_DETAILS_MERCHANT:
    "carhire-customer-merchant/customer/service/consumers/details",
  KYC_DETAILS_MERCHANT:
    "carhire-customer-merchant/customer/service/consumers/details",
  INSURANCE_COMPANY_LIST_MERCHANT:
    "carhire-customer-merchant/customer/service/master/insurance-companies",
  PRODUCT_LIST: "carhire-product-merchant/products/get-products",
  FULL_ACCESS:
    "carhire-customer-merchant/customer/service/consumers/dummy-approval",
  BUSINESS_TYPE:
    "carhire-customer-merchant/customer/service/master/businessdetails",
  FORGOT_PASSWORD:
    "carhire-customer-merchant/customer/service/consumers/forgotPassword",
  MERCHANT_LIST:
    "carhire-customer-merchant/customer/service/consumers/merchants/odoo",
  UPDATE: "carhire-customer-merchant/customer/service/consumers",
  CREATE_CART: "carhire-order/order/service/consumers/orders/create",
  ORDER_DETAILS: "carhire-order/order/service/merchants",
  DELIVERY_SLOT_UPDATE: "carhire-order/order/service/merchants/statusUpdate",

  INSURANCE_CART: "carhire-customer-merchant/customer/service/consumers",
  INSURANCE_COMPANY_SEARCH:
    "carhire-customer-merchant/customer/service/master/insurance-companies-keyword",
  GARAGE_SEARCH:
    "carhire-customer-merchant/customer/service/merchant/details/basic-details",
  DELIVERYBOY_LIST:
    "carhire-customer-merchant/customer/service/merchant/details/delivery-boys",
  // "carhire-customer-merchant/customer/service/admin/customers",

  DELIVERYBOY_DETAILS:
    "carhire-customer-merchant/customer/service/admin/delivery-partner/",
  DELIVERBOY_APPROVAL:
    "carhire-customer-merchant/customer/service/admin/merchants/status",
  DELIVERY_FEE:
    "carhire-order/order/service/deliveryfee/calculate-delivery-fee",
  DELIVERYBOY_ASSIGN: "carhire-order/order/service/deliveryPartner",
  BANK: `/carhire-customer-merchant/customer/service/master/getBankDetails/all`,
  PROFILE: `/carhire-customer-merchant/customer/service/consumer/account-settings`,
  SEARCH_RESULTS: `/carhire-order/order/service/consumers/orders/get-orders/details`,
  GET_COMMENTS: "carhire-order/order/service/consumers/orders/get-message",
  BULK_APPROVAL: `/carhire-order/order/service/merchants/update/multiple_orders`,
  EXPORTLIST_SEARCH_RESULTS:
    "/carhire-order/order/service/merchants/all-orders?page=0&size=100",

  //PRODUCT

  PRODUCT_CATEGORY_LIST:
    "carhire-product-merchant/category?planet_id=CarRentalPlanet",
  CREATE_PRODUCT: "carhire-product-merchant/products",
  PRODUCT_IMAGE_UPLOAD:
    "carhire-customer-merchant/customer/service/uploadProductImage",
  FEATURE_LIST: "carhire-product-merchant/products/features/all",
  VIEW_PRODUCT_DETAILS: `carhire-product-merchant/products/`,
  PRODUCT_DELETE: `carhire-product-merchant/products/enable_disable`,
  PURCHASE_ORDER: `carhire-order/order/service/merchants`,
  APPROVE_REJECT: `carhire-order/order/service/merchants/statusUpdate`,

  CUSTOMER_DOCUMENTS: `carhire-customer-merchant/customer/service/documents`,
  PRODUCT_BULKUPLOAD: "carhire-product-merchant/upload/products",
  INSURANCE_PRODUCT_LISTING: `carhire-product-consumer/get-insurance-products`,

  //GARAGE

  GARAGE_ORDER_LISTING: "carhire-order/order/service/garage/order_details",
  GARAGE_ORDER_DETAILS: "carhire-order/order/service/garage/order-details",
  GARAGE_STATUS_UPDATE:
    "carhire-order/order/service/garage/update-garage-status",
  EXTRA_AMOUNT:
    "carhire-product-consumer/cart-amount-config/getCartAmountConfiguration",
  ADDITIONAL_DRIVER_FEE:
    "carhire-order/order/service/deliveryfee/list-driver_fee",
  VEHICLE_REASSIGN: "carhire-product-merchant/products/available-products",
  SLOT: "carhire-order/order/service/merchants/get-delivery-slot",
  CAR_CHANGE_UPDATE:
    "carhire-order/order/service/merchants/order-product-change",
  RENTAL_DAYS: "carhire-order/order/service/merchants/update/rental-days",
  REFRESH_TOKEN: "carhire-customer-merchant/authentication/oauth/refresh/token",
  BOOKING_CALENDER:
    "carhire-order/order/service/consumers/orders/order/status/timeline",

  //LPo

  ORDER_EDIT: `carhire-order/order/service/merchants/order-change-request`,

  //NOTIFICATION

  NOTIFICATION_COUNT:
    "notification/notification/get_viewed_notifications/BACKOFFICE_ORDER_ACCEPT",
  NOTIFICATION_UPDATE: "notification/notification/update/push_notification",
};

export { baseUrl, API_URL };
