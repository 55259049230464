import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { GarageNavBar, GarageSideBar } from "../../../components";
import { notificationSound } from "../../../assets/sounds";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";

const GarageDashBoard = () => {
  const [currentPage, setCurrentPage] = useState("dashboard");
  const [notifications, setNotifications] = useState<string[]>([]);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const establishWebSocketConnection = (token: string) => {
      const socket = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}/hello?authorization=${token}`
      );

      socket.onopen = () => {
        console.log("Garage WebSocket connection established");
        socket.send(JSON.stringify({ event: "login", data: { token: token } }));
      };

      socket.onmessage = (event) => {
        console.log("Message from server", event.data);
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          event.data,
        ]);
        setNewNotificationsCount((prevCount) => prevCount + 1);
        playNotificationSound();
        showToastNotification(event.data);
      };

      socket.onclose = () => {
        console.log("Garage WebSocket connection closed");
      };

      socket.onerror = (error) => {
        console.log("Garage WebSocket error", error);
      };
    };

    const token = localStorage.getItem("access_token");
    if (token) {
      establishWebSocketConnection(token);
    }

    return () => {};
  }, []);

  const handleNotificationClick = (notification: string) => {
    let targetPath = "/garage/insuranceorder";
    // let targetPath = "";

    // if (notification.includes("new claim")) {
    //   targetPath = "/garage/claims";
    // } else {
    //   targetPath = "/garage/insuranceorder";
    // }

    navigate(targetPath, { state: { notification } });

    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n !== notification)
    );
  };

  const playNotificationSound = () => {
    try {
      const audio = new Audio(notificationSound);
      audio
        .play()
        .then(() => {
          console.log("Notification sound played successfully");
        })
        .catch((error) => {
          console.error("Failed to play notification sound:", error);
        });
    } catch (error) {
      console.error("Error initializing audio:", error);
    }
  };

  const showToastNotification = (message: string) => {
    toast(message);
  };

  return (
    <div className="col-md-12 col-xl-12 col-sm-12 container-fluid dashboardcontainer">
      <div className="row flex-nowrap">
        <GarageSideBar
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <div className="col dashboardright garage-bg d-flex justify-content-center">
          <div className="dashboardright-width">
            <GarageNavBar
              currentPage={currentPage}
              notifications={notifications}
              handleNotificationClick={handleNotificationClick}
              newNotificationsCount={newNotificationsCount}
              setNewNotificationsCount={setNewNotificationsCount}
            />
            <div className="dashboardright-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarageDashBoard;
