import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormControl,
  Pagination,
  Row,
} from "react-bootstrap";
import { dashboard_vertical_three_dots } from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse, Invoice } from "../../../types";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";

const ApprovedOrders = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);
  const merchantId =
    decodedToken && decodedToken["merchant-id"]
      ? decodedToken["merchant-id"]
      : null;

  const roles =
    decodedToken && decodedToken["roles"] ? decodedToken["roles"] : null;
  const showBranchColumn = roles?.includes("ROLE_MERCHANT_OPERATION");

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchResults, setSearchResults] = useState<Invoice[] | null>(null);
  const [showExportDateField, setShowExportDateField] = useState(false);
  const [exportDate, setExportDate] = useState("");
  const [showList, setShowList] = useState(false);
  const [exportList, setExportList] = useState<Invoice[] | null>(null);
  const [dateChange, seDateChange] = useState(false);

  const fetchApprovedOrders = async (page: number) => {
    const response = await axiosInstance.get(
      `${API_URL.PURCHASE_ORDER}/${merchantId}?status=MERCHANT_ORDER_ACCEPT&page=${page}`
    );
    return response?.data;
  };

  const { data: approvedOrders } = useQuery<ApiResponse>({
    queryKey: ["approvedOrders", currentPage],
    queryFn: () => fetchApprovedOrders(currentPage),
    refetchInterval: 30000,
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleExport = () => {
    setShowExportDateField(true);
  };

  //----------------------------------------------

  const handleList = async () => {
    const payload = {
      status: "MERCHANT_ORDER_ACCEPT",
      order_type: "DELIVERED",
      date: exportDate,
    };

    try {
      const response = await axiosInstance.post(
        API_URL.EXPORTLIST_SEARCH_RESULTS,
        payload
      );
      setExportList(response?.data?.content || []);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    // setExportDate("");
    setShowList(true);
  };

  //-----------------------------------------------

  const handleExportSubmit = () => {
    if (exportList && exportList.length > 0) {
      const fileName = `ApprovedOrders_${exportDate}.xlsx`;

      const header = [
        "#",
        "Order Number",
        "Number of Days",
        "Customer Name",
        "Product Name",
        "Date",
      ];

      const exportData = exportList.map((invoice, index) => [
        index + 1,
        invoice?.orderId ?? "",
        invoice?.rental_days ?? "",
        invoice?.customer_name ?? "",
        invoice?.products?.[0]?.productName ?? "",
        formatDate(invoice?.createdAt) ?? "",
      ]);

      const worksheet = XLSX.utils.aoa_to_sheet([header, ...exportData]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Order List");

      XLSX.writeFile(workbook, fileName);
    }
    setExportDate("");
    setShowList(false);
  };

  const handleSearch = async () => {
    let date_filter_key = "";
    if (searchCriteria === "order_date") {
      date_filter_key = searchCriteria.toLowerCase().replace(" ", "_");
    }

    const payload = {
      order_number: searchCriteria === "order_number" ? searchQuery : "",
      date_filter_key: date_filter_key,
      date: searchCriteria === "order_date" ? searchQuery : "",
      insurance_claim: searchCriteria === "insurance_claim" ? searchQuery : "",
    };

    try {
      const response = await axiosInstance.post(
        API_URL.SEARCH_RESULTS,
        payload
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    }
    setSearchQuery("");
  };

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const dateFormatting = (date: Date | null) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setExportDate(formattedDate);
      seDateChange(true);
    } else {
      setExportDate("");
    }
  };

  const ordersToDisplay = searchResults || approvedOrders?.content;

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedToday = `${year}-${month}-${day}`;
    setExportDate(formattedToday);
  }, []);

  useEffect(() => {
    handleList();
  }, [showExportDateField]);

  useEffect(() => {
    if (dateChange) {
      handleList();
      seDateChange(false);
    }
  }, [dateChange]);

  return (
    <div className="row">
      <div className="col-md-12 mb-3 mt-2">
        {!showExportDateField && (
          <Form.Group as={Row} className="align-items-center">
            <Col sm="3">
              <Form.Select
                className="custom-select"
                aria-label="Select search criteria"
                value={searchCriteria}
                onChange={(e) => setSearchCriteria(e.target.value)}
              >
                <option value="">Search by</option>
                <option value="order_number">Order Number</option>
                <option value="order_date">Date</option>
                <option value="insurance_claim">Insurance Claim</option>
              </Form.Select>
            </Col>
            {searchCriteria && (
              <>
                <Col sm="5">
                  <FormControl
                    className="search-bar"
                    aria-label="Text input with select"
                    placeholder={
                      searchCriteria === "order_date"
                        ? "DD-MM-YYYY"
                        : "Enter your Search"
                    }
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Col>
                <Col sm="2">
                  <Button className="search-button" onClick={handleSearch}>
                    Search
                  </Button>
                </Col>
              </>
            )}
          </Form.Group>
        )}
        {!showExportDateField && !searchCriteria && (
          <Row className="d-flex justify-content-end">
            <Col sm="2">
              <Button
                variant="primary"
                className="export-button"
                onClick={handleExport}
              >
                Export
              </Button>
            </Col>
          </Row>
        )}
        {showExportDateField && (
          <Row className="mt-3">
            <Col sm="3">
              <DatePicker
                className="search-bar-export"
                id="datetimepicker"
                selected={
                  exportDate && /^\d{4}-\d{2}-\d{2}$/.test(exportDate)
                    ? new Date(exportDate)
                    : null
                }
                onChange={(date: Date | null) => {
                  dateFormatting(date);
                }}
                dateFormat="dd-MM-yyyy"
                customInput={
                  <input
                    readOnly
                    className="input-date"
                    value={
                      exportDate
                        ? exportDate.split("-").reverse().join("-")
                        : ""
                    }
                  />
                }
                popperClassName="hide-calendar"
                showPopperArrow={true}
                autoComplete="off"
                minDate={new Date()}
              />
            </Col>
            <Col sm="2">
              {!showList && (
                <Button className="export-button" onClick={handleList}>
                  Search List
                </Button>
              )}
              {showList && (
                <Button className="export-button" onClick={handleExportSubmit}>
                  Export
                </Button>
              )}
            </Col>
            {/* <Col sm="1">
              <Button
                className="close-button"
                variant="danger"
                onClick={() => {
                  setShowExportDateField(false);
                  setExportDate("");
                  setShowList(false);
                  setExportList(null);
                }}
              >
                X
              </Button>
            </Col> */}
          </Row>
        )}
      </div>

      <div className="col-md-12 dashboard-table-div">
        <table className="table table-hover dashboard-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              {showBranchColumn && <th>Branch</th>}
              <th>Number of Days</th>
              <th>Customer Name</th>
              <th>Product Name</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {exportList
              ? exportList?.map((invoice: Invoice, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice?.orderId}</td>
                    {showBranchColumn && <td>{invoice?.merchantName}</td>}

                    <td>{invoice?.rental_days}</td>
                    <td>{invoice?.customer_name}</td>
                    <td>{invoice?.products?.[0]?.productName}</td>
                    <td>
                      <span className="invoice-date-td">
                        {formatDate(invoice?.createdAt)}
                      </span>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="Dropdown-table"
                        >
                          <img
                            src={dashboard_vertical_three_dots}
                            width="15"
                            height="15"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="Dropdown-table-item">
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/details?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-top Dropdown-table-item-link"
                          >
                            View Details
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/assign?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-item-link"
                          >
                            Assign
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/edit?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-bottom Dropdown-table-item-link"
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              : ordersToDisplay?.map((invoice: Invoice, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{invoice?.orderId}</td>
                    {showBranchColumn && <td>{invoice?.merchantName}</td>}
                    <td>{invoice?.rental_days}</td>
                    <td>{invoice?.customer_name}</td>
                    <td>{invoice?.products?.[0]?.productName}</td>
                    <td>
                      <span className="invoice-date-td">
                        {formatDate(invoice?.createdAt)}
                      </span>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="Dropdown-table"
                        >
                          <img
                            src={dashboard_vertical_three_dots}
                            width="15"
                            height="15"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="Dropdown-table-item">
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/details?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-top Dropdown-table-item-link"
                          >
                            View Details
                          </Dropdown.Item>
                          {!showBranchColumn && (
                            <Dropdown.Item
                              as={Link}
                              to={`/merchant/accepted/assign?OrderId=${invoice.orderId}`}
                              className="Dropdown-table-list Dropdown-table-item-link"
                            >
                              Assign
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            as={Link}
                            to={`/merchant/accepted/edit?OrderId=${invoice.orderId}`}
                            className="Dropdown-table-list Dropdown-table-border-bottom Dropdown-table-item-link"
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>

        {!searchResults && approvedOrders && (
          <Pagination className="pagination-align">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            />
            {Array.from({ length: approvedOrders.totalPages }, (_, index) => (
              <Pagination.Item
                key={index}
                active={index === currentPage}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === approvedOrders.totalPages - 1}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default ApprovedOrders;
